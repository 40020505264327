































import { getAvailableLanguages } from '@/config/i18n.ts';
import LoginForm from '@/components/auth/LoginForm.vue';
import Vue from 'vue';
import { mapStores } from 'pinia';
import { useUsersStore } from '@/store/users-store';
import { LoginDto } from '@/dto/login.dto';
import { CalendarResourceType } from '@/store/calendar-store';

export default Vue.extend({
  components: {
    LoginForm
  },
  data() {
    return {
      errors: {},
      type: 'password',
      eye: 'eye',
      availableLanguages: getAvailableLanguages(),
      loggingInExtras: false
    };
  },
  computed: {
    ...mapStores(useUsersStore),
    loggingIn(): boolean {
      return this.usersStore.user.loggingIn;
    },
    isLoggedIn(): boolean {
      return this.usersStore.isLoggedIn;
    },
    loggedInUserId(): number | null {
      return this.usersStore.loggedInUserId;
    }
  },
  methods: {
    showHidePassword() {
      if (this.type === 'password') {
        this.type = 'text';
        this.eye = 'eye-slash';
      } else {
        this.type = 'password';
        this.eye = 'eye';
      }
    },
    setLanguage(language: string) {
      this.usersStore.setLanguage(language);
    },
    async submitLogin(loginDto: LoginDto) {
      this.loggingInExtras = true;
      try {
        await this.usersStore.login(loginDto);
        if (this.isLoggedIn) {
          // On s'assure que la langue du navigateur est bien la langue enregistrée dans le compte client
          await this.setLanguage(this.$i18n.locale);
          await this.usersStore.getUser(this.loggedInUserId);
          let routerRedirect = (this.$router as any).history.current.query
            .redirect;
          if (this.isStaffOrMore) {
            routerRedirect =
              routerRedirect === undefined ? 'Calendar' : routerRedirect;
            if (routerRedirect === 'Calendar') {
              await this.$router.replace({
                name: routerRedirect,
                query: { viewType: CalendarResourceType.EMPLOYEE }
              });
            } else {
              await this.$router.replace({ name: routerRedirect });
            }
          } else {
            await this.$router.replace({
              name: 'MyAccount'
            });
          }
        }
        this.loggingInExtras = false;
      } catch (exception) {
        this.loggingInExtras = false;
      }
    }
  }
});
